import { createApi } from '@reduxjs/toolkit/query/react';
import { btBaseQuery } from 'src/Common/common';

export const oreBillApi = createApi({
  reducerPath: 'oreBillApi',
  baseQuery: btBaseQuery,
  tagTypes: ['Bill'],

  endpoints: (builder) => ({
    findAllBills: builder.query({
      query: (company) => `bill/${company}`,
      providesTags: ['Bill']
    }),
    findBillById: builder.query({
      query: (id) => `bill/byid/${id}`,
      providesTags: ['Bill']
    }),
    insertBill: builder.mutation({
      query: (bill) => ({
        url: 'bill',
        method: 'POST',
        body: bill
      }),
      invalidatesTags: ['Bill']
    }),
    updateBill: builder.mutation({
      query: (bill) => ({
        url: 'bill',
        method: 'PUT',
        body: bill
      }),
      invalidatesTags: ['Bill']
    }),
    deleteBill: builder.mutation({
      query: (id) => ({
        url: `bill/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Bill']
    })
  })
});

export const {
  useFindAllBillsQuery,
  useFindBillByIdQuery,
  useInsertBillMutation,
  useUpdateBillMutation,
  useDeleteBillMutation
} = oreBillApi;
